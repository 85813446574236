<template>
  <v-container>
    <!-- BOC:[error] -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <v-card
      v-if="!api.isError"
      :loading="api.isLoading"
      elevation="0"
      class="mx-auto"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-card-title>{{ $t("model.name.events") }}</v-card-title>
      <v-card-text v-if="data.length > 0" class="pb-0 mb-0">{{seriesCurrent.name}}</v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody v-if="!api.isLoading">
            <tr class="text-center" v-if="data.length == 0">
              <td colspan="7" class="grey--text">
                {{ $t("api.response.no_data") }}
              </td>
            </tr>
            <template v-for="(item, index) in data">
              <tr v-if="item.Series.id == seriesCurrent.id" :key="index">
                <td  width="100">
                  <EventGamePoster :data="item"></EventGamePoster>
                </td>
                <td width="200">
                  {{ item.name }}
                </td>
                <td  width="250"><EventDate :data="item"></EventDate></td>
                <td  width="100">
                  <EventStatus :data="item" />
                </td>
                <td width="150" >
                  <template v-if="$moment(item.timestampStart).diff($moment(),'days') < 30">
                    <v-btn
                      :to="{
                        name: 'PageMainEventScoreboard',
                        params: { eventCode: item.code },
                      }"
                      xl
                      color="yellow"
                      class="view-event text-right"
                    >
                      {{$t('action.view')}}
                    </v-btn>
                  </template>
                  <template v-else>
                    <div class="text-capitalize">{{$moment(item.timestampStart).locale($_getLocaleForMoment()).fromNow()}}</div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
          <div v-if="api.isLoading">
            <ASkeletonLoader type="table-tbody" width="80%" />
          </div>
        </template>
      </v-simple-table>
      <!---->
      <v-card-text v-if="data.length > 0" class="pb-0 mb-0">{{ $t("string.event_past") }}</v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody v-if="!api.isLoading">
            <template v-for="(item, index) in data">
              <tr v-if="item.Series.id != seriesCurrent.id" :key="index">
                <td width="100">
                  <EventGamePoster :data="item"></EventGamePoster>
                </td>
                <td  width="200">
                  {{ item.name }}
                </td>
                <td  width="250"><EventDate :data="item"></EventDate></td>
                <td  width="100">
                  <EventStatus :data="item" />
                </td>
              <td width="150" v-if="auth.Session && auth.Account.type == 'moderator'" >
                  <template v-if="$moment(item.timestampStart).diff($moment(),'days') < 30">
                    <v-btn
                      :to="{
                        name: 'PageMainEventScoreboard',
                        params: { eventCode: item.code },
                      }"
                      xl
                      color="yellow"
                      class="view-event text-right"
                    >
                      {{$t('action.view')}}
                    </v-btn>
                  </template>
                  <template v-else>
                    <div class="text-capitalize">{{$moment(item.timestampStart).locale($_getLocaleForMoment()).fromNow()}}</div>
                  </template>
                </td>
                <td width="150" v-else ></td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import EventStatus from "@/components/Event/EventStatus";
import EventDate from "@/components/Event/EventDate";
import EventGamePoster from "@/components/Event/EventGamePoster";
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    EventStatus,
    EventDate,
    EventGamePoster,
  },
  data: () => ({
    buttonText: "",
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    seriesCurrent: null,
    seriesPrevious: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var data = resp;
      this.seriesCurrent = this.$_.maxBy(data,(o)=>{
        return o.Series.id;
      }).Series
      this.data = this.$_.filter(data, (o) => {
        return o.festivalId == null;
      });
    };
    //EOC
  },

  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/main/event";
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.fetch();
    this.buttonText = this.$t("action.view");
  },
};
</script>

<style>
.view-event::before {
  background-color: transparent !important;
}
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-data-table__wrapper {
  -ms-overflow-style: none;
}
</style>